<template>
  <div class="row">
    <div class="col-md-6">
        <card class="vertical-form" title="Horizontal Form" style="height: calc(100vh - 80px);">

          <div slot="header">
              <h4 class="card-title">Productos</h4>
              <p class="card-category">Lista, Edición y Borrado de fichas</p>
          </div>
          <div class="table-responsive table-full-width">
            <el-table :data="tableData" :row-class-name="tableRowClassName" style="overflow: scroll; height: calc(100vh - 200px);">
                <el-table-column label="Nombre" property="nombre" width="220"></el-table-column>
                <el-table-column label="Familia" property="nomfam" width="100"></el-table-column>
                <el-table-column label="Referencia" property="referencia" width="100"></el-table-column>
                <el-table-column label="Acciones">
                  <template slot-scope="props">
                      <a class="btn-neutral btn-simple btn-link btn-lg2" v-tooltip.top-center="'Edita'" @click="handleEdit(props.$index, props.row)">
                        <i class="fa fa-edit"></i>
                      </a>
                      <a class="btn-danger btn-simple btn-link btn-lg2" v-tooltip.top-center="'Borra'" @click="handleDelete(props.$index, props.row)">
                        <i class="fa fa-times"></i>
                      </a>
                  </template>
                </el-table-column>
            </el-table>
          </div>

        </card>
    </div> <!-- end card -->
    <div class="col-md-6">
      <card class="stacked-form" title="Ficha de Producto">
        <template slot="header">
          <h4 class="card-title">
            Ficha de Producto
          </h4>
        </template>
        <div class="col-12">
          <fg-input label="id"
            disabled=""
            inputClasses="col-md-3 col-3"
            placeholder="Id"
            v-model="producto.id">
          </fg-input>
          <fg-input label="Nombre"
            inputClasses="col-md-12 col-12"
            placeholder="Nombre"
            v-model="producto.nombre">
          </fg-input>
          <fg-input label="Referencia"
            inputClasses="col-md-9 col-9"
            placeholder="Referencia"
            v-model="producto.referencia">
          </fg-input>
          <span style="color: gray; font-size: small;">FAMILIA</span><br>
          <el-select size="large" placeholder="Elige familia" v-model="producto.familia_id">
            <el-option v-for="familia in familias" :value="familia.id" :label="familia.nombre" :key="familia.id">
            </el-option>
          </el-select>

        </div>
        <br>
        <l-button type="primary" v-if="(producto.id!==undefined) || (producto.id==undefined && producto.nombre!==undefined)" @click="limpia" style="margin-left: 10px;">
          Limpia
        </l-button>
        <l-button type="primary" v-if="producto.id!==undefined" @click="modifica" style="margin-left: 10px;">
          Modifica
        </l-button>
        <l-button type="primary" v-if="producto.id==undefined && producto.nombre!==undefined" @click="alta" style="margin-left: 10px;">
          Alta
        </l-button>
      </card>
    </div> <!-- end card -->
  </div>


</template>
<script>
  import Swal from 'sweetalert2'
  import 'sweetalert2/dist/sweetalert2.css'
  import { Checkbox, Radio } from 'src/components/index'
  import {Table, TableColumn, Select, Option} from 'element-ui'
  import axios from "axios";
  import crypt from "crypto-js";
  import AES from "crypto-js/aes";

  export default {
    components: {
      Checkbox,
      Radio,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn,
      [Select.name]: Select,
      [Option.name]: Option,
    },
    data () {
      return {
        tableData: [],
        producto: {nombre:'',familia_id:'0',referencia:''},
        familias: {},
        colores: [{value: '1', label: '', color: 'bc1'},
        {value: '2', label: '', color: 'bc2'},
        {value: '3', label: '', color: 'bc3'},
        {value: '4', label: '', color: 'bc4'},
        {value: '5', label: '', color: 'bc5'},
        {value: '6', label: '', color: 'bc6'},
        {value: '7', label: '', color: 'bc7'},
        {value: '8', label: '', color: 'bc8'},
        {value: '9', label: '', color: 'bc9'},
        {value: '10', label: '', color: 'bc10'},
        {value: '11', label: '', color: 'bc11'},
        {value: '12', label: '', color: 'bc12'},
        {value: '13', label: '', color: 'bc13'},
        {value: '14', label: '', color: 'bc14'},
        {value: '15', label: '', color: 'bc15'},
        {value: '16', label: '', color: 'bc16'},
        {value: '17', label: '', color: 'bc17'},
        {value: '18', label: '', color: 'bc18'},
        {value: '19', label: '', color: 'bc19'},
        {value: '20', label: '', color: 'bc20'},
        {value: '21', label: '', color: 'bc21'}]
      }
    },
    created() {
      this.cargafamilias()
    },
    mounted() {
      this.cargalista()
    },
    methods: {
      cargalista() {
        var mijson = {
          funcion: "listaLJ",
          tabla: "producto",
          campos: "a.id, a.nombre, a.familia_id, a.referencia",
          where: "",
          order: "",
          ljtabla: "familia",
          ljid: "familia_id",
          ljcampo: "nombre",
          ljalias: "nomfam"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.tableData = resp
          this.cargafamilias()
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      cargafamilias() {
        var mijson = {
          funcion: "lista",
          tabla: "familia",
          campos: "*",
          where: "",
          orden: ""
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          this.familias = resp
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      tableRowClassName (row) {
        var micolor = 0
        console.log(row)
        this.familias.forEach( function(valor, indice, fam) {
          if (row.row.familia_id==valor.id) {
            micolor = valor.color
          }
        })
        var clase = ''
        if (micolor>0) {
          clase = this.colores[micolor-1].color
        }
        return clase
      },
      handleEdit (index, row) {
        this.producto = row
      },
      handleDelete (index, row) {
        this.producto = row
        var borrproducto = this.producto.nombre
        var self = this
        Swal.fire({
          title: 'Estás seguro?',
          text: 'Realmente quieres borrar el producto: '+borrproducto,
          icon: 'question',
          showCancelButton: true,
          confirmButtonClass: 'btn btn-success btn-fill',
          cancelButtonClass: 'btn btn-danger btn-fill',
          confirmButtonText: 'Si, borrarlo!',
          cancelButtonText: 'Cancelar',
          buttonsStyling: false
        }).then(function (valor) {
          if (valor.isDismissed == true) {
            console.log("cancelaron")
          } else {
            self.baja()
            Swal.fire({
              title: '¡Atención!',
              text: '¡Ficha de producto '+borrproducto+' eliminada!',
              timer: 1500,
              showConfirmButton: false
            })
          }
        })
      },
      limpia() {
        this.producto={nombre:'',familia_id:'0',referencia:''}
      },
      modifica() {
        var mijson = {
          funcion: "modifica",
          tabla: "producto",
          campos: "nombre, familia_id, referencia",
          valores: "'"+this.producto.nombre+"', '"+this.producto.familia_id+"', '"+this.producto.referencia+"'",
          id: String(this.producto.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      alta() {
        var mijson = {
          funcion: "alta",
          tabla: "producto",
          campos: "nombre, familia_id, referencia",
          valores: "'"+this.producto.nombre+"', '"+this.producto.familia_id+"', '"+this.producto.referencia+"'"
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      },
      baja() {
        var mijson = {
          funcion: "baja",
          tabla: "producto",
          id: String(this.producto.id)
        }
        const params = new URLSearchParams();
        params.append('cadena', AES.encrypt(JSON.stringify(mijson),this.$store.state.salvoconducto));
        axios.post('https://'+this.$store.state.urlserver+':'+this.$store.state.puertoserver, params)
        .then(respuesta => {
          var resp = JSON.parse(crypt.enc.Utf8.stringify(AES.decrypt(respuesta.data, this.$store.state.salvoconducto)))
          console.log(resp)
          if (resp.resp=="OK") {
            this.producto={}
            this.cargalista()
          }
        })
        .catch(error => {
          if (error.response) {
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
          } else if (error.request) {
              console.log(error.request);
          } else {
              console.log('Error', error.message);
          }
          console.log(error.config);
        })
      }
    }


  }
</script>
<style>
  .el-table .bc1 {
    background: #F08080;
  }
  .el-table .bc2 {
    background: #FFA07A;
  }
  .el-table .bc3 {
    background: #FFB6C1;
  }
  .el-table .bc4 {
    background: #FFA500;
  }
  .el-table .bc5 {
    background: #FFFACD;
  }
  .el-table .bc6 {
    background: #FFE4B5;
  }
  .el-table .bc7 {
    background: #F0E68C;
  }
  .el-table .bc8 {
    background: #E6E6FA;
  }
  .el-table .bc9 {
    background: #DDA0DD;
  }
  .el-table .bc10 {
    background: #66CDAA;
  }
  .el-table .bc11 {
    background: #3CB371;
  }
  .el-table .bc12 {
    background: #AFEEEE;
  }
  .el-table .bc13 {
    background: #B0C4DE;
  }
  .el-table .bc14 {
    background: #6495ED;
  }
  .el-table .bc15 {
    background: #FFEBCD;
  }
  .el-table .bc16 {
    background: #D2B48C;
  }
  .el-table .bc17 {
    background: #F0F8FF;
  }
  .el-table .bc18 {
    background: #FAEBD7;
  }
  .el-table .bc19 {
    background: #FAF0E6;
  }
  .el-table .bc20 {
    background: #FFF0F5;
  }
  .el-table .bc21 {
    background: #FFE4E1;
  }
</style>
